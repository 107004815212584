import { Routes as Router, Route } from 'react-router-dom';
import React, { useEffect } from 'react';
import AppLayout from 'modules/layouts/app/AppLayout.component';
import RouteWrapper from './components/RouteWrapper.component';
import AuthFormLayout from 'modules/layouts/auth/AuthFormLayout.component';
import InboundCalculator from 'modules/inbound-calculator/InboundCalculator.component';
import { useAuth } from 'modules/auth/AuthProvider.component';
import { parseSessionTime } from 'utils/parseTime';
import { addNotification } from 'store/actions/notifications.actions';
import { useSocket } from 'modules/scheduler/hooks/useSocket';
import { useAppDispatch } from 'store/hooks/store.hooks';
import { useSnackbar } from 'notistack';

const AlertsList = React.lazy(() => import('modules/alerts/AlertsList.component'));
const AlertsCRU = React.lazy(() => import('modules/alerts/AlertsCRU.component'));
const HomePage = React.lazy(() => import('modules/home/Home.component'));
const CampaignListPage = React.lazy(() => import('modules/campaigns/CampaignList.component'));
const SignInFormPage = React.lazy(() => import('modules/signIn/SignInForm.component'));
const ChangePasswordFormPage = React.lazy(() => import('modules/changePassword/ChangePasswordForm.component'));
const CampaignCRUPage = React.lazy(() => import('modules/campaigns/CampaignCRU.component'));
const CalculationDetailPage = React.lazy(() => import('modules/campaigns/CalculationDetail.component'));
// const NewOutboundPage = React.lazy(() => import('modules/outbound/NewOutbound.component'));
// const ForecastsPage = React.lazy(() => import('modules/outbound/Forecasts.component'));
// const SimulationOutboundPage = React.lazy(() => import('modules/outbound/SimulationOutbound.component'));
// const DashboardOutboundPage = React.lazy(() => import('modules/outbound/DashboardOutbound.component'));
const RequestCRU = React.lazy(() => import('modules/requests/RequestCRU.component'));
const RequestList = React.lazy(() => import('modules/requests/RequestList.component'));
const RolesList = React.lazy(() => import('modules/users/roles/RolesList.component'));
const RolesCRU = React.lazy(() => import('modules/users/roles/RolesCRU.component'));
const CongratulationRoles = React.lazy(() => import('modules/users/roles/CongratulationRoles.component'));
const MyDashboard = React.lazy(() => import('modules/Supervisor/MyDashboard.component'));
// const NewInboundPage = React.lazy(() => import('modules/inbound/NewInbound.component'));
const DemoPage = React.lazy(() => import('modules/demo/Demo.component'));
const ClientsList = React.lazy(() => import('modules/clients/ClientsList.component'));
const ClientsCRU = React.lazy(() => import('modules/clients/ClientsCRU.component'));
const EmployeesList = React.lazy(() => import('modules/employees/EmployeesList.component'));
const EmployeesCRU = React.lazy(() => import('modules/employees/EmployeesCRU.component'));
const MyProfile = React.lazy(() => import('modules/profile/UserProfile.component'));
const TagsList = React.lazy(() => import('modules/tags/TagsList.component'));
const TrainingsCRU = React.lazy(() => import('modules/trainings/TrainingsCRU.component'));
const TrainingsList = React.lazy(() => import('modules/trainings/TrainingsList.component'));
const MyDashboardOperator = React.lazy(() => import('modules/MyDashboardOperator/MyDashboardOperator.component'));
const ConfigurationSetupView = React.lazy(() => import('modules/setup-config/ConfigurationSetupView.component'));
const ConfiguratorBreaks = React.lazy(() => import('modules/setup-config/ConfiguratorBreaks'));
const LicenseToConfigure = React.lazy(() => import('modules/setup-config/LicenseToConfigure.component'));
const NotFound = React.lazy(() => import('modules/not-found/NotFound.component'));
const Page404 = React.lazy(() => import('modules/not-found/Page404.component'));
const TeamsLists = React.lazy(() => import('modules/Teams/TeamsLists.component'));
const TeamsCRU = React.lazy(() => import('modules/Teams/TeamsCRU.component'));
const ShiftsList = React.lazy(() => import('modules/shifts/ShiftsList.component'));
const ShiftsCRU = React.lazy(() => import('modules/shifts/ShiftsCRU.component'));
const ShiftSuccessfullyCreated = React.lazy(() => import('modules/shifts/ShiftSuccessfullyCreated.component'));
// scheduler
const SchedulerList = React.lazy(() => import('modules/scheduler/SchedulersList.page'));
const SchedulerCRU = React.lazy(() => import('modules/scheduler/SchedulerCRU.page'));
const AssistedScheduler = React.lazy(() => import('modules/scheduler/AssistedScheduler.page'));
// Notifications
const NotificationsList = React.lazy(() => import('modules/notifications/Notifications.component'));
const Routes = () => {
  const { enqueueSnackbar } = useSnackbar();
  const sessionTime = process.env.REACT_APP_SESSION_TIME;
  const parsedSessionTime = sessionTime ? parseSessionTime(sessionTime) : 3600000;
  const { refreshSessionToken, user } = useAuth();
  const dispatch = useAppDispatch();

  useSocket({
    events: {
      connect: () => console.log('connected'),
      disconnect: () => console.log('disconnected'),
      message: data => {
        console.log(data);
        enqueueSnackbar('Mensaje recibido', { variant: 'success' });
        dispatch(addNotification(data));
      },
    },
  });

  useEffect(() => {
    if (user) {
      refreshSessionToken();
      const interval = setInterval(() => {
        refreshSessionToken();
      }, parsedSessionTime);
      return () => clearInterval(interval);
    }
  }, [user]);
  return (
    <Router>
      <Route element={<AppLayout />}>
        <Route index element={<RouteWrapper element={<HomePage />} />} />
        {user?.role.configurator_access && (
          <>
            <Route path="/setup-configuration" element={<RouteWrapper element={<ConfigurationSetupView />} />} />
            <Route path="/setup-configuration/:id" element={<RouteWrapper element={<LicenseToConfigure />} />} />
            <Route path="/break-configuration" element={<RouteWrapper element={<ConfiguratorBreaks />} />} />
          </>
        )}
        <Route path="/alerts" element={<RouteWrapper element={<AlertsList />} />} />
        <Route path="alerts/new" element={<RouteWrapper element={<AlertsCRU enableForm />} />} />
        <Route path="alerts/:id" element={<RouteWrapper element={<AlertsCRU />} />} />
        <Route path="alerts/:id/edit" element={<RouteWrapper element={<AlertsCRU enableForm />} />} />
        <Route path="/reports/my-dashboard" element={<RouteWrapper element={<MyDashboard />} />} />
        <Route path="/reports/my-dashboard-operator" element={<RouteWrapper element={<MyDashboardOperator />} />} />
        <Route path="/shifts" element={<RouteWrapper element={<ShiftsList />} />} />
        <Route path="/shifts/new" element={<RouteWrapper element={<ShiftsCRU enableForm />} />} />
        <Route path="/shifts/:id" element={<RouteWrapper element={<ShiftsCRU />} />} />
        <Route path="/shifts/:id/edit" element={<RouteWrapper element={<ShiftsCRU enableForm />} />} />
        <Route path="/shift-successfully-created" element={<RouteWrapper element={<ShiftSuccessfullyCreated />} />} />

        <Route path="/campaigns" element={<RouteWrapper element={<CampaignListPage />} />} />
        <Route path="/campaigns/new" element={<RouteWrapper element={<CampaignCRUPage enableForm />} />} />
        <Route path="/campaigns/:id" element={<RouteWrapper element={<CampaignCRUPage />} />} />
        <Route path="/campaigns/:id/:inboundID" element={<RouteWrapper element={<CalculationDetailPage />} />} />
        <Route path="/campaigns/:id/edit" element={<RouteWrapper element={<CampaignCRUPage enableForm />} />} />
        {/* <Route path="/campaigns/outbound/new" element={<RouteWrapper element={<NewOutboundPage />} />} />
        <Route path="/campaigns/outbound/forecasts" element={<RouteWrapper element={<ForecastsPage />} />} />
        <Route
          path="/campaigns/outbound/simulation/:id"
          element={<RouteWrapper element={<SimulationOutboundPage />} />}
        />
        <Route
          path="/campaigns/outbound/dashboard/:id"
          element={<RouteWrapper element={<DashboardOutboundPage />} />}
        /> */}
        {/* <Route path="/campaigns/inbound/new" element={<RouteWrapper element={<NewInboundPage />} />} /> */}
        <Route path="/inbound-calculator" element={<RouteWrapper element={<InboundCalculator />} />} />
        <Route path="/inbound-calculator/:campaignID" element={<RouteWrapper element={<InboundCalculator />} />} />
        <Route path="/requests" element={<RouteWrapper element={<RequestList />} />} />
        <Route path="/requests/new/:license" element={<RouteWrapper element={<RequestCRU enableForm />} />} />
        <Route path="/requests/:id" element={<RouteWrapper element={<RequestCRU />} />} />
        <Route path="/requests/:id/edit/:license" element={<RouteWrapper element={<RequestCRU enableForm />} />} />
        <Route path="/roles" element={<RouteWrapper element={<RolesList />} />} />
        <Route path="/roles/new" element={<RouteWrapper element={<RolesCRU enableForm />} />} />
        <Route path="/roles/:id" element={<RouteWrapper element={<RolesCRU />} />} />
        <Route path="/roles/:id/edit" element={<RouteWrapper element={<RolesCRU enableForm />} />} />
        <Route path="/congratulation-roles" element={<RouteWrapper element={<CongratulationRoles />} />} />
        <Route path="/clients" element={<RouteWrapper element={<ClientsList />} />} />
        <Route path="/clients/new" element={<RouteWrapper element={<ClientsCRU enableForm />} />} />
        <Route path="/clients/:id" element={<RouteWrapper element={<ClientsCRU />} />} />
        <Route path="/clients/:id/edit" element={<RouteWrapper element={<ClientsCRU enableForm />} />} />
        <Route path="/employees" element={<RouteWrapper element={<EmployeesList />} />} />
        <Route path="/employees/new" element={<RouteWrapper element={<EmployeesCRU enableForm />} />} />
        <Route path="/employees/:id" element={<RouteWrapper element={<EmployeesCRU />} />} />
        <Route path="/employees/:id/edit" element={<RouteWrapper element={<EmployeesCRU enableForm />} />} />
        <Route path="/my-profile" element={<RouteWrapper element={<MyProfile />} />} />
        <Route path="/tags" element={<RouteWrapper element={<TagsList />} />} />
        <Route path="/trainings" element={<RouteWrapper element={<TrainingsList />} />} />
        <Route path="/trainings/new" element={<RouteWrapper element={<TrainingsCRU enableForm />} />} />
        <Route path="/trainings/:id" element={<RouteWrapper element={<TrainingsCRU />} />} />
        <Route path="/trainings/:id/edit" element={<RouteWrapper element={<TrainingsCRU enableForm />} />} />
        <Route path="equipos" element={<RouteWrapper element={<TeamsLists />} />} />
        <Route path="equipos/new" element={<RouteWrapper element={<TeamsCRU enableForm />} />} />
        <Route path="equipos/:id" element={<RouteWrapper element={<TeamsCRU />} />} />
        <Route path="equipos/:id/edit" element={<RouteWrapper element={<TeamsCRU enableForm />} />} />
        <Route path="/404" element={<RouteWrapper element={<Page404 />} />} />
        <Route path="/schedulers" element={<RouteWrapper element={<SchedulerList />} />} />
        <Route path="/assisted-scheduler" element={<RouteWrapper element={<AssistedScheduler />} />} />
        <Route path="/schedulers/new" element={<RouteWrapper element={<SchedulerCRU enableForm />} />} />
        <Route path="/schedulers/:id" element={<RouteWrapper element={<SchedulerCRU />} />} />
        <Route path="/schedulers/:id/edit" element={<RouteWrapper element={<SchedulerCRU enableForm />} />} />
        <Route path="/notifications" element={<RouteWrapper element={<NotificationsList />} />} />
      </Route>

      <Route element={<RouteWrapper element={<AuthFormLayout />} />}>
        <Route path="/sign-in" element={<SignInFormPage />} />
        <Route path="/change-password" element={<ChangePasswordFormPage />} />
      </Route>

      <Route path="/demo" element={<DemoPage />} />

      <Route path="*" element={<RouteWrapper element={<NotFound />} />} />
    </Router>
  );
};

export default Routes;
