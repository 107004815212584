export type Meta = {
  page: number;
  take: number;
  itemCount: number;
  pageCount: number;
  hasPreviousPage: boolean;
  hasNextPage: boolean;
};

export enum ConfigTabDisplay {
  CREATE = 'create',
  EDIT = 'edit',
  VIEW = 'view',
}

export type TrainingFormProps = {
  configTabDisplay: ConfigTabDisplay;
  trainingId?: string;
  headerTitle: string;
};

export const initialMeta: Meta = {
  page: 1,
  take: 25,
  itemCount: 0,
  pageCount: 0,
  hasPreviousPage: false,
  hasNextPage: false,
};

export type queryParamsType = {
  startDate?: string;
  endDate?: string;
  searchBy: string;
  searchText: string;
  fieldsToReturn?: 'all' | 'specific';
  page?: number;
  take?: number;
};

export type QueryToSend = {
  start_date?: string;
  end_date?: string;
  value?: string;
  field?: string;
  page?: number;
  fieldsToReturn?: string;
  take?: number;
};

export type ServiceQueryStringParamsType = {
  fieldsToReturn?: string;
  field?: string;
  value?: string;
  page?: number;
};

export interface ResourceInterface {
  id: string;
  name: string;
  value?: string;
  label?: string;
}

export type TagType = {
  id: string;
  name: string;
  category?: {
    id?: string;
    name: string;
  };
};

export interface DTOError {
  property: string;
  children: [];
  constraints: {
    matches: string;
    maxLength: string;
  };
}

export interface CRUError {
  response: {
    data: {
      errors: Array<DTOError>;
      message: {
        message: string;
      };
    };
  };
}

export interface OperatorEntity {
  id: string;
  firstname: string;
  lastname: string;
  calls: number;
  callsHour: number;
  effectiveManagement: number;
  timeLogin: number;
  reducers: number;
  contacts: number;
  talkingTime: number;
}

export const initialOperatorEntity: OperatorEntity = {
  id: '',
  firstname: '',
  lastname: '',
  calls: 0,
  callsHour: 0,
  effectiveManagement: 0,
  timeLogin: 0,
  reducers: 0,
  contacts: 0,
  talkingTime: 0,
};
export enum Charts {
  CALLS_METRIC = 'callsMetric',
  CALLS_METRIC_RELATIVE = 'callsMetricRelative',
  CALLS_METRIC_ABSOLUTE = 'callsMetricAbsolute',
  EFFECTIVE_MANAGEMENT_METRIC = 'effectiveManagementMetric',
  TMO_METRIC = 'tmoMetric',
  AVERAGE_TIME_CONVERSATION_METRIC = 'averageTimeConversationMetric',
  REAL_AVERAGE_TIME_CONVERSATION_METRIC = 'realAverageTimeConversationMetric',
  REAL_AVERAGE_TIME_CONVERSATION_METRIC_PER_USER = 'realAverageTimeConversationMetricPerUser',
  TOTAL_CALLS_AND_EFFECTIVENESS_PER_USER = 'totalCallsAndEffectivenessPerUser',
  CALLS_CHART = 'callsChart',
  EFFECTIVE_MANAGEMENT_CHART1 = 'effectiveManagementChart1',
  EFFECTIVE_MANAGEMENT_CHART2 = 'effectiveManagementChart2',
  HEADCOUNT = 'headCount',
  DASHBOARD_OPERATOR_FULL = 'dashboardOperatorFull',
  OPERATORS_TABLE = 'operatorsTable',
  OPERATOR_DETAIL = 'operatorDetail',
  TMO_METRIC_ABSOLUTE = 'tmoMetricAbsolute',
  ATENTION_TIME_METRIC = 'atentionTimeMetric',
  TOTAL_CALLS_AND_EFFECTIVENESS = 'totalAndEffectiveness',
  EFFECTIVENESS_PER_USER = 'effectivenessPerUser',
}

export enum LicensesNames {
  DISEASE = 'Enfermedad',
  GREMIAL_PERMISSION = 'Permiso gremial',
  SUSPENSION = 'Suspensión',
  FAMILY_ILLNESS = 'Enfermedad familiar',
  MOURNING = 'Duelo',
  EXAM = 'Examen',
  JUSTIFIED = 'Justificado',
  VACATION = 'Vacaciones',
  MOVING = 'Mudanza',
  BLOOD_DONATION = 'Donaciòn de Sangre',
  UNJUSTIFIED = 'Injustificado',
  ART = 'Art',
  UNPAID_LICENSE = 'Lic sin goce de sueldo',
  MATERNITY_LICENSE = 'Lic. por Maternidad',
  PATERNITY_LICENSE = 'Lic. por Paternidad',
  MARRIAGE_LICENSE = 'Matrimonio',
  MATERNITY_LICENSE_2 = 'Excedencia',
  JOB_RESERVATION = 'Reserva de puesto',
  ABSENT_WITHOUT_NOTIFICATION = 'Ausente sin Aviso',
  PROCEDURES = 'Trámites o citaciones',
  EXTRA_HOURS = 'Horas Extras',
  SHIFT_CHANGE = 'Cambio de Horario',
}

export enum TimeAgregation {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export enum Mode {
  DETAIL = 'detail',
  SUM = 'sum',
}
