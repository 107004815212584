import { configureStore } from '@reduxjs/toolkit';
import {
  campaignsSlice,
  requestsSlice,
  usersSlice,
  rolesSlice,
  forecastSlice,
  clientsSlice,
  employeesSlice,
  tagsSlice,
  trainingsSlice,
  licensesSlice,
  dashboardSlice,
  teamsSlice,
  shiftSlice,
  schedulersSlice,
  alertsSlice,
  notificationsSlice,
} from './slices';

const store = configureStore({
  reducer: {
    campaigns: campaignsSlice.reducer,
    requests: requestsSlice.reducer,
    users: usersSlice.reducer,
    roles: rolesSlice.reducer,
    forecast: forecastSlice.reducer,
    clients: clientsSlice.reducer,
    employees: employeesSlice.reducer,
    tags: tagsSlice.reducer,
    trainings: trainingsSlice.reducer,
    licenses: licensesSlice.reducer,
    dashboard: dashboardSlice.reducer,
    teams: teamsSlice.reducer,
    shifts: shiftSlice.reducer,
    schedulers: schedulersSlice.reducer,
    alerts: alertsSlice.reducer,
    notifications: notificationsSlice.reducer,
  },
  devTools: process.env.NODE_ENV !== 'production',
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({
      thunk: {
        extraArgument: '',
      },
    }),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;

export default store;
